<script>
  $(document).ready(function(){
    $('[data-toggle="tooltip"]').tooltip();
  });
</script>
<div class="main-content row" style="background-image: url('assets/img/Background.svg');">
  <div class="container-fluid">
      <div class="row">
          <div class="col-12">
              <div class="card">
                  <div class="card-header card-header-info card-header-icon">
                      <span>
                          <h4 class="card-icon" style="background:#00AFB4;">Playground</h4>
                      </span>
                      <!--
                      <span>
                          <h4 class="card-icon" style="background:#00AFB4;">{{ user.name }}</h4>
                      </span>
                      -->

                      <button type="button" class="btn btn-primary pull-right" style="background-color: #EF7D00;" (click)="ejecutar()" [disabled]="setButtonJugar()">
                          {{ btnJugar }}
                      </button>

                      <!--(click)="setConnection()"-->
                      <button type="button" hint class="btn btn-secundary pull-right" [ngStyle]="{'background-color': setDisconnectBgBtn() }" [disabled]="sendingProgram">
                          {{ btnStatus }}
                      </button>

                       <button type="button" class="btn btn-info pull-right" style="background-color: #4a779c; padding: 0px; height: 41px; width: 41px;" [disabled]="sendingProgram" data-toggle="modal" data-placement="top" title="Guarda tu workspace en tu inventario" data-target="#exampleModal" data-whatever="@getbootstrap">
                          <i class="material-icons large" style="color: white; font-size: 2rem;">save</i>
                      </button> 

                  </div>
                  <div class="card-body card-body">
                      <div id="blocklyDiv" style="height: 60vh; width: 100%;"></div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
<!--Save workspace Modal-->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel">Guarda tu workspace</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <!--Value: {{ WSForm.value | json }}-->
      <form [formGroup]="WSForm">

        <div class="form-group">
              <label class="col-form-label">Nombre:</label>
              <mat-form-field>
                <input matInput type="text" class="form-control" id="savews-name" formControlName="name">
                <mat-error *ngIf="WSFormName.invalid && WSFormName.errors.maxlength">
                  Debe tener un maximo de 20 caracteres
                </mat-error>
              </mat-form-field>
              <label for="savews-description" class="col-form-label">Descripcion:</label>
              <mat-form-field >
                <textarea matInput class="form-control" id="savews-description" formControlName="description"></textarea>
                <mat-error *ngIf="WSFormDescription.invalid">
                  {{ WSFormDescription.errors }}
                </mat-error>
              </mat-form-field>
          <label for="savews-trigger" class="col-form-label">Ejecutar con:</label>
          <mat-form-field >
            <textarea matInput class="form-control" id="savews-trigger" formControlName="trigger"></textarea>
            <mat-error *ngIf="WSFormDescription.invalid">
              {{ WSFormDescription.errors }}
            </mat-error>
          </mat-form-field>
      </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            <button type="submit" (click)="saveWorkspace(WSForm.controls.name.value,WSForm.controls.description.value,WSForm.controls.trigger.value)" class="btn btn-primary">Guardar</button>
          </div>
      </form>
    </div>
  </div>
</div>
</div>
<!--Edit workspace Modal-->
<div class="modal fade" id="editWorkspaceModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel">Edita tu Workspace</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <!--Value: {{ EditForm.value | json }}-->
      <form [formGroup]="EditForm">
        <div class="form-group">
              <label class="col-form-label">Nombre:</label>
              <mat-form-field>
                <input matInput type="text" class="form-control" id="editws-name" formControlName="name">
                <mat-error *ngIf="EditFormName.invalid && EditFormName.errors.maxlength">
                  Debe tener un maximo de 20 caracteres
                </mat-error>
              </mat-form-field>
              <label for="editws-description" class="col-form-label">Descripcion:</label>
              <mat-form-field >
                <textarea matInput class="form-control" id="editws-description" formControlName="description"></textarea>
                <mat-error *ngIf="EditFormDescription.invalid">
                  {{ EditFormDescription.errors }}
                </mat-error>
              </mat-form-field>
          <label for="editws-trigger" class="col-form-label">Ejecutar con:</label>
          <mat-form-field >
            <textarea matInput class="form-control" id="editws-trigger" formControlName="trigger"></textarea>
            <mat-error *ngIf="EditFormTrigger.invalid">
              {{ EditFormTrigger.errors }}
            </mat-error>
          </mat-form-field>
      </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            <button type="submit" (click)="UpdateWorkspace(workspaceTarget,EditFormName.value,EditFormDescription.value,EditFormTrigger.value)" class="btn btn-primary">Guardar</button>
          </div>
      </form>
    </div>
  </div>
</div>
</div>