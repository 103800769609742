import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { User } from "../models/user.model";
import { Token } from "../models/token.model";
import { environment } from "../../environments/environment";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { ApiV2Service } from "./api-v2.service";

@Injectable()
export class AuthenticationService {
  private currentTokenSubject: BehaviorSubject<Token>;
  public currentTokenValue: Observable<Token>;

  private currentUserSubject: BehaviorSubject<User>;
  public currentUserValue: Observable<User>;

  // private currentOrganizationsSubject: BehaviorSubject<Organization[]>;
  //public currentOrganizationsValue: Observable<Organization[]>;

  // private currentMainOrganizationSubject: BehaviorSubject<Organization>;
  //public currentMainOrganizationValue: Observable<Organization>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private api: ApiV2Service
  ) {
    this.currentTokenSubject = new BehaviorSubject<Token>(
      JSON.parse(localStorage.getItem("token"))
    );
    this.currentTokenValue = this.currentTokenSubject.asObservable();
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("user"))
    );
    this.currentUserValue = this.currentUserSubject.asObservable();
  }

  public setToken(token: string): void {
    localStorage.setItem("token", token);
  }

  public getToken(): string {
    const token = JSON.parse(localStorage.getItem("token"));
    if (token && token.key) {
      return token.key;
    } else {
      return null;
    }
  }

  public get currentToken(): Token {
    return this.currentTokenSubject.value;
  }

  

  public get isLoggeIn(): boolean {
    const hasToken =
      this.currentTokenSubject.value && this.currentTokenSubject.value.key;
    const hasUser = this.currentUserSubject.value;
    return hasToken && hasUser ? true : false;
  }

  public set isLoggeIn(state) {}

  login(username: string, password: string) {
    console.log("Iniciando sesión");
    const body: any = {
      email: username,
      password: password,
    };
    return this.http
      .post<Token>(`https://simaknowledge.com/api/rest-auth/login/`, body)
      .pipe(
        map((token) => {
          if (token.key) {
            localStorage.setItem("token", JSON.stringify(token));
            this.currentTokenSubject.next(token);
            //this.init();
            return token;
          } else {
            this.currentTokenSubject.next(null);
            return null;
          }
        })
      );
  }

  init() {
    //this.getUserSession();
    //    this.getCategories();
  }

  
  /* login(username: string, password: string) {

    const params: HttpParams = new HttpParams()
      .append('email', username)
      .append('pass', password)
      .append('app', '4Sd568wqf4j855J4235GLd56b99');

    const options = {
      params: params
    }
    // console.log(headers, params, options);
    return this.http.get<User>(`http://simarobot.com/sima-profesor/web/LoginInApp`, options);
  } */

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("token");
    localStorage.removeItem("user");
}
}
