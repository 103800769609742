import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Orden } from '../models/orden.model';
import { MethodCall } from '@angular/compiler';

@Injectable()
export class UploadDataService {

  endPointUrl = 'https://control.simarobot.com/api/comandos';
  httpOptions: any;

  constructor(private http: HttpClient) {

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
      })
    }
  }

  uploadData(body: Orden): Observable<any> {

    return this.http.post(this.endPointUrl, body, this.httpOptions);

  }

}
