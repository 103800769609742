import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiV2Service {

  constructor(private http: HttpClient, private router: Router) { }

  getService(url: string, paramsInfo?: any) {
    const params: HttpParams = paramsInfo ? paramsInfo : null;
    const options = params ? { params: params } : {};
    return this.http.get(`${environment.apiUrlBase}${environment.endpoints.v2.PREFIX}${environment.endpoints.v2[url]}`, options);
  }
  getService2(url: string, paramsInfo?: any) {
    const params: HttpParams = paramsInfo ? paramsInfo : null;
    const options = params ? { params: params } : {};
    return this.http.get(`${environment.apiUrlBase}${environment.endpoints.v2.PREFIX}${url}`, options);
  }

  postService(url: string, body: any) {
    return this.http.post(`${environment.apiUrlBase}${environment.endpoints.v2.PREFIX}${environment.endpoints.v2[url]}`, body);
  }

  patchService(url: string, body: any) {
    return this.http.patch(`${environment.apiUrlBase}${environment.endpoints.v2.PREFIX}${environment.endpoints.v2[url]}`, body);
  }

  deleteService(url: string) {
    return this.http.delete(`${environment.apiUrlBase}${environment.endpoints.v2.PREFIX}${environment.endpoints.v2[url]}`);
  }
  deleteServiceBody(url: string,body) {
    return this.http.delete(`${environment.apiUrlBase}${environment.endpoints.v2.PREFIX}${environment.endpoints.v2[url]}`,body);
  }
  
  getAlternativeService(url: string, path: string | number, paramsInfo?: any) {
    url = environment.endpoints.v2[url].replace(/\{(.*?)\}/, path);
    const params: HttpParams = paramsInfo ? paramsInfo : null;
    const options = params ? { params: params } : {};
    return this.http.get(`${environment.apiUrlBase}${environment.endpoints.v2.PREFIX}${url}`, options);
  }

  postAlternativeService(url: string, body: any, path: string | number) {
    url = environment.endpoints.v2[url].replace(/\{(.*?)\}/, path);
    return this.http.post(`${environment.apiUrlBase}${environment.endpoints.v2.PREFIX}${url}`, body);
  }

  patchAlternativeService(url: string, body: any, path: string | number) {
    url = environment.endpoints.v2[url].replace(/\{(.*?)\}/, path);
    return this.http.patch(`${environment.apiUrlBase}${environment.endpoints.v2.PREFIX}${url}`, body);
  }

  deleteAlternativeService(url: string, path: string | number,) {
    url = environment.endpoints.v2[url].replace(/\{(.*?)\}/, path);
    return this.http.delete(`${environment.apiUrlBase}${environment.endpoints.v2.PREFIX}${url}`);
  }

  deleteAlternativeServiceBody(url: string, body:any, path: string | number) {
    url = environment.endpoints.v2[url].replace(/\{(.*?)\}/, path);
    return this.http.delete(`${environment.apiUrlBase}${environment.endpoints.v2.PREFIX}${url}`, body);
  }

  getFile(url: string) {
    return `${environment.apiUrlBase}${environment.endpoints.v1.PREFIX}${url}`;
  }

  postFile(url: string, fileToUpload: File, type?: string): Observable<any> {
    return Observable.create((observer: any) => {
      let fileData: FormData = new FormData();
      fileData.append("file_type", type);
      fileData.append("uploaded_file", fileToUpload, fileToUpload.name);

      let xhr: XMLHttpRequest = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 201) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(JSON.parse(xhr.response));
          }
        }
      };
      //console.log(localStorage.getItem('token'));
      xhr.open("POST", `${environment.apiUrlBase}${environment.endpoints.v2.PREFIX}${environment.endpoints.v2[url]}`);
      xhr.setRequestHeader("Authorization", localStorage.getItem('token') ? `Token ${JSON.parse(localStorage.getItem('token'))['key']}` : '');
      xhr.send(fileData);
    });
  }

  postFile2(url: string, fileToUpload: File, type?: string): Observable<any> {
    return Observable.create((observer: any) => {
      let fileData: FormData = new FormData();
      fileData.append("profile_group_id", type);
      fileData.append("users_file", fileToUpload, fileToUpload.name);

      let xhr: XMLHttpRequest = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(JSON.parse(xhr.response));
          }
        }
      };
      //console.log(localStorage.getItem('token'));
      xhr.open("POST", `${environment.apiUrlBase}${environment.endpoints.v2.PREFIX}${environment.endpoints.v2[url]}`);
      xhr.setRequestHeader("Authorization", localStorage.getItem('token') ? `Token ${JSON.parse(localStorage.getItem('token'))['key']}` : '');
      xhr.send(fileData);
    });
  }

  getExpression(id: number) {
    return `../../assets/img/expressions/expression${id}.svg`;
  }

}
