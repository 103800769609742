import { Component, OnInit, AfterViewInit } from '@angular/core';
import * as moment from 'moment';
import { WebSocketService } from '../services/web-socket.service';
import { Orden } from '../models/orden.model';
import Swal from 'sweetalert2';
import { User } from '../models/user.model';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Workspace } from 'app/models/workspace.model';
import { WorkspacesService } from 'app/services/workspaces.service';
import { Status } from 'app/models/status.model';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
declare var Blockly: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})


export class DashboardComponent implements OnInit {

  btnJugar = 'Jugar';
  btnStatus = 'Desconectado'
  sendingProgram = false;
  workspacePlayground: any;
  connected = false;
  public toolbox: string;
  public xml: XMLHttpRequest = new XMLHttpRequest();
  private user: User = JSON.parse(localStorage.getItem('user'));
  public WSForm : FormGroup;
  public EditForm : FormGroup;
  public workspaceTarget : Workspace;
  public workspaces : Workspace[] = [/*{
    
    id:2,
    name:'TEST WORKSPACE',
    description:'TEST WORKSPACE DESCRIPTION',
    content:'example content',
    triggerer:'hola'
  },
  {
    id:2,
    name:'TEST WORKSPACE',
    description:'TEST WORKSPACE DESCRIPTION',
    triggerer:'hola',
    content:'example content'
  },
  {
    id:2,
    name:'TEST WORKSPACE',
    description:'TEST WORKSPACE DESCRIPTION',
    triggerer:'hola',
    content:'example content'
  },
  {
    id:2,
    name:'TEST WORKSPACE',
    description:'TEST WORKSPACE DESCRIPTION',
    triggerer:'hola',
    content:'example content'
  }*/];

  constructor(private fb: FormBuilder, private socket: WebSocketService, private WorkspaceService: WorkspacesService) {
   }

  ngOnInit() {
    this.socket.enabledDevices=[];
    //console.log(this.user)
    this.createBlocks();
    this.socket.connectRobot();
    this.getConnectionStatus();
    Swal.fire({
      text: '¡Conecta tu SimaApp a SimaCode para poder empezar!',
      icon: 'success'
    } as any);
    this.InitWSForm();
    this.InitEditForm();
  }

  InitWSForm(){
    this.WSForm = this.fb.group({
      name:['',[Validators.required,Validators.maxLength(20)]],
      description:['',Validators.maxLength(150)],
      trigger:['',Validators.maxLength(50)]
    });
  }

  InitEditForm(){
    this.EditForm = this.fb.group({
      name:['',[Validators.required,Validators.maxLength(20)]],
      description:['',Validators.maxLength(150)],
      trigger:['',Validators.maxLength(50)]
    });
  }

  get WSFormName(){
    return this.WSForm.get('name');
  }

  get WSFormDescription(){
    return this.WSForm.get('description');
  }

  get WSFormTrigger(){
    return this.WSForm.get('trigger');
  }

  get EditFormName(){
    return this.EditForm.get('name');
  }

  get EditFormDescription(){
    return this.EditForm.get('description');
  }

  get EditFormTrigger(){
    return this.EditForm.get('trigger');
  }

  

  setButtonJugar() {
console.log(this.socket.enabledDevices.length)
    const code = Blockly.JavaScript.workspaceToCode(this.workspacePlayground);
    //console.log('jugar',code)
    if (code !== '' && !this.sendingProgram && this.btnJugar === 'Jugar' && this.socket.enabledDevices.length > 0) {
      return false;
    }
    return true;

  }

  createBlocks() {

    this.xml.open('GET', 'assets/blockly/blocks.xml', false);
    this.xml.send();
    const xmlFinal = this.xml.responseXML;
    this.toolbox = new XMLSerializer().serializeToString(xmlFinal);
    this.workspacePlayground = Blockly.inject('blocklyDiv', { toolbox: this.toolbox });
  

  }

  ejecutar() {

    this.btnJugar = 'Procesando...';
    this.sendingProgram = true;
    Blockly.JavaScript.addReservedWords('code');
    const code = Blockly.JavaScript.workspaceToCode(this.workspacePlayground);
    console.log('code',code)// respuesta cruda 
    // tslint:disable-next-line: no-eval
    //Transform code from json to app
    const codex = eval(code);// corre el codigo y crear las respuestas para ser enviadas a la app
    
    console.log('codex',codex)
    const today = new Date();
    const date = moment(today.getTime()).format('YYYY-MM-DD HH:mm:ss');
    //get user session information
    const token = localStorage.getItem('token');
    const tokenParse: User = JSON.parse(token);
    const mac = tokenParse.usuario_id;

    const body: Orden = {
      fecha: date,
      mac: mac,
      orden: JSON.stringify(codex)
    };
    console.log('body',body.orden)

    setTimeout(() => {
      this.socket.sendProgram(body.orden);
      this.btnJugar = 'Hecho';
      Swal.fire({
        title: 'Buen Trabajo',
        text: 'SIMA está ejecutando el programa',
        icon: 'success',
        onClose: () => {
          this.btnJugar = 'Jugar';
          this.sendingProgram = false;
        }
      } as any);
    }, 1000);
    // tslint:disable-next-line: no-eval
    eval('start()');

  }

  setConnection() {

    if (this.connected) {
      this.socket.disconnectRobot();
    } else {
      this.socket.connectRobot();
    }

  }

  parseJwt(jwt: string) {

    const base64Url = jwt.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));

  };

  setDisconnectBgBtn() {
if(this.socket.enabledDevices.length>0)
    return '#00c853' 
    else
    return '#d50000'

  }

  getConnectionStatus() {

    this.socket.getConnectionStatus().subscribe(
      (connected: boolean) => {
        if (connected && !this.connected) {
          this.connected = true;
          this.btnStatus = 'Conectado';
          /*
          Swal.fire({
            title: 'Dispositivo conectado',
            text: 'Ahora SIMA está conectado, ¡Vamos a programar!',
            type: 'success'
          });
          */
        } else {
          if(!connected&&this.connected&&this.socket.enabledDevices.length<1){
            this.connected = false;
            this.btnStatus = 'Desconectado';
            /*
            Swal.fire({
              title: 'Desconectando',
              text: 'Ahora SIMA está desconectado',
              type: 'info'
            });
            */
          }
        }
      },
      (error: any) => {
        console.error(error);
        Swal.fire({
          title: 'Opsss...',
          text: 'Parece que hubo un problema en la casa de SIMA',
          icon: 'error',
          confirmButtonColor: '#F27474',
          onClose: () => {
            this.btnJugar = 'Jugar';
          }
        } as any);
      }
    );

  }

  saveWorkspace(name:string,description:string,triggerer:string){
    if(name&&description){
      var xml = Blockly.Xml.workspaceToDom( Blockly.mainWorkspace );
      let content = Blockly.Xml.domToText(xml);
      const ws: Workspace = {
        id: this.user.usuario_id,
        name:name,
        description:description,
        content: content,
        triggerer:triggerer
      };
      this.WorkspaceService.uploadWorkspace(ws).subscribe((response:Status)=>{
        if(response.status){
          this.getWorkspacesList();
          Swal.fire({
            title: '',
            text: 'Workspace '+name+' guardado',
            icon: 'success',
            onClose: () => {
            }
          } as any);
        }else{
          console.error(response.msg);
          Swal.fire({
            title: 'Opsss...',
            text: 'Parece que hubo un problema en la casa de SIMA',
            icon: 'error',
            onClose: () => {
            }
          } as any);
        }
      });
    }else{
      Swal.fire({
        title: 'Opsss...',
        text: 'Se necesitan todos los campos para guardar',
        icon: 'error',
        onClose: () => {
        }
      } as any);
    }
  }

  OnWorkspaceEditButton(workspace: Workspace){
    this.EditForm.patchValue({
      name: workspace.name,
      description:  workspace.description,
      trigger: workspace.triggerer
    });
    this.workspaceTarget = workspace;
  }

  UpdateWorkspace(oldWorkspace : Workspace,newName:string,newDescription:string,newTrigger: string){
    let newWorkspace : Workspace = {
      id : oldWorkspace.id,
      name : newName,
      description : newDescription,
      triggerer : newTrigger,
      content : oldWorkspace.content
    };
      this.WorkspaceService.updateWorkspace(newWorkspace).subscribe((resp: Status)=>{
        if(resp.status){
          this.getWorkspacesList();
          Swal.fire({
            title: '¡Listo!',
            text: "Workspace actualizado correctamente",
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK',
          });
        }else{
          Swal.fire({
            title: 'Oops...',
            text: "Hubo un error al intentar modificar el workspace:\n"+resp.msg,
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });
        }
      },(error : HttpErrorResponse)=>{
        Swal.fire({
          title: 'Oops...',
          text: "Hubo un error al intentar modificar el workspace:\n"+error.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });
      });
  }

  getWorkspacesList() {
    try {
    this.WorkspaceService.downloadWorkspaces(this.user.usuario_id).subscribe((resp: HttpResponse<Workspace[]>)=>{
      if(resp.ok){
        let ws = resp.body;
        this.workspaces = ws;
      }
    },(error: HttpErrorResponse)=>{
      let ws : Workspace = {
        id : 1,
        name : 'asdasd',
        description : 'asdasd',
        triggerer: 'asdasd'
      }
      this.workspaces.push(ws);
      console.error(error.error);
    });
    } catch (error) {
      console.error(error);
    }
  }
  
  WorkspaceLaunch(workspace : Workspace){
    console.log(workspace);
    Swal.fire({
        title: '¿Estas seguro en cargar tu workspace?',
        text: "Los bloques se agregarán a tu workspace actual",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {
          var xml =  Blockly.Xml.textToDom(workspace.content);
          Blockly.Xml.domToWorkspace(Blockly.mainWorkspace,xml);
          Swal.fire(
            'Listo!',
            'success'
          );
        }
      })
  }

  public WorkspaceDelete(workspace : Workspace){
    Swal.fire({
        title: '¿Estas seguro que deseas eliminarlo?',
        text: "Todo el contenido de ese workspace se perderá",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {
          this.WorkspaceService.deleteWorkspaces(workspace.id).subscribe((response : Status)=>{
            if(response.status){
                Swal.fire(
                  'Listo!',
                  'Workspace eliminado correctamente',
                  'success');
              }else{
                Swal.fire(
                  'Oops...!',
                  'Error al borrar workspace: '+response.msg,
                  'error');
              };
              this.getWorkspacesList();
          },(error: HttpErrorResponse)=>{
            console.error(error);
            Swal.fire(
              'Oops!',
              'Error al conectar con el servidor',
              'error');
          });
        }
      })
  }

}
