import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from 'app/services/authentication.service';
import { User } from '../models/user.model';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router,private auth: AuthenticationService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        
       /*  const token: User = JSON.parse(localStorage.getItem('token'));
        if (token && token.status && (route.url[0].toString() !== 'login')) {
            // logged in so return true
            return true;
        } */
        const token = this.auth.currentToken;
        //const user = this.login.currentUser;
        if (token && token.key && !state.url.includes('auth') ) {
          // logged in so return true
          return true;
        }
    
        // not logged in so redirect to login page with the return url
        this.auth.isLoggeIn;
        this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
        //this.router.navigate(['/auth/login']);
        return false;
      }
}
