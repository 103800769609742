export const ENDPOINTS = {
  v1: {
    PREFIX: '/api/'
  },
  v2: {
    PREFIX: '/api/v2',
    // Autenticación
    LOGIN: '/rest-auth/login/',
    SIGNUP: '/rest-auth/registration/',
    FORGOT_PASSWORD: '/rest-auth/password/reset/',
    UPDATE_PASSWORD: '/rest-auth/password/change/',
    CONFIRM_PASSWORD: '/rest-auth/password/reset/confirm/',
    CONFIRM_ACCOUNT: '/rest-auth/registration/verify-email/',
    // Usuario (User)
    GET_USER_SESSION: '/user/',
    EDIT_USER_BY_ID: '/user/{user_id}/',
    // Organización (Organization)
    GET_ORGANIZATIONS: '/organization/',
    GET_ORGANIZATION_BY_ID: '/organization/{organization_id}/',
    // Cursos (Courses, Profilegroup)
    CREATE_COURSE: '/profilegroup/',
    EDIT_COURSE: '/profilegroup/{profilegroup_id}/',
    ADD_LESSONS_TO_COURSE: '/profilegroup/{lesson_id}/addgrades/',
    REMOVE_LESSONS_TO_COURSE: '/profilegroup/{lesson_id}/removegrades/',
    GET_COURSES_BY_ORGANIZATION: '/profilegroup/',
    GET_COURSES_BY_ORGANIZATION_BY_PARAMS: '/profilegroup/',
    GET_COURSE_BY_ID: '/profilegroup/{profilegroup_id}/',
    DELETE_COURSE: '/profilegroup/{profilegroup_id}/',
    // Clases (Lessons, Grades)
    CREATE_LESSON: '/grade/',
    EDIT_LESSON: '/grade/{grade_id}/',
    DELETE_LESSON: '/grade/{grade_id}/',
    GET_LESSONS: '/grade/',
    GET_LESSONS_BY_ORGANIZATION: '/grade/organization/?limit=100',
    GET_LESSON_BY_ID: '/grade/{grade_id}/',
    GET_LESSON_BY_PROFILE: '/grade/',
    GET_LESSON_BY_COURSE: '/grade/',
    INSTALL_LESSON: '/grade/{grade_id}/install/',
    SHOW_HIDE_LESSON: '/grade/{grade_id}/',
    // Perfiles de Usuario (Profile)
    CREATE_PROFILE: '/profile/',
    CREATE_PROFILE_ON_BEHALF: '/profile/on_behalf/',
    EDIT_PROFILE: '/profile/{profile_id}',
    GET_PROFILE: '/profile/',
    GET_PROFILES: '/user/profiles/',
    GET_PROFILES_BY_COURSE: '/profilegroup/{profilegroup_id}/profiles/?limit=1000',
    CREATE_ANSWER: '/answer/',
    GET_ANSWERS: '/answer/',
    ADD_PROFILES_TO_COURSE: '/profilegroup/{profilegroup_id}/add/',
    REMOVE_PROFILES_TO_COURSE: '/profilegroup/{profilegroup_id}/remove/',
    GET_PROFILES_BY_ORGANIZATION: '/organization/{organization_id}/profiles/?limit=1000',

    // Actividades
    CREATE_ACTIVITY: '/activity/',
    EDIT_ACTIVITY: '/activity/{activity_id}/',
    DELETE_ACTIVITY: '/activity/{activity_id}/',
    GET_ACTIVITIES: '/activity/?include_scenes=false',
    GET_ACTIVITY_BY_ID: '/activity/{activity_id}/',
    GET_GET_ACTIVITIES_BY_ORGANIZATION: '/activity/?include_scenes=false',
    GET_ACTIVITIES_BY_PARAMS: '/activity/?include_scenes=false',
    GET_ACTIVITIES_BY_COURSE: '/activity/?include_scenes=false',
    GET_ACTIVITIES_BY_LESSON: '/activity/?include_scenes=false',// '/grade/{grade}/activities',
    GET_ACTIVITIES_BY_LESSON_2: '/grade/{grade}/activities/?include_scenes=false',
    GET_ACTIVITIES_BY_CATEGORY: '/activity/?include_scenes=false',
    SHOW_HIDE_ACTIVITY: '/activity/{activity_id}/',
    // ATTACH_IMAGE_OR_AUDIO: '/scene/{scene_id}/',
    ATACH_MULTIMEDIA: '/mediafile/',
    // Categorías (Categories, Tags)
    GET_CATEGORIES: '/tag/?limit=1000000',
    CREATE_CATEGORIES: '/tag/',
    // Movimientos
    GET_MOVES: '/movement/?limit=1000000',
    CREATE_MOVE: '/movement/',
    // Users
    GET_USERS_BY_ORGANIZATIONS: "/organization/{organization_id}/users/?limit=1000",
    CREATE_TEACHER_OR_ADMIN_USER: "/organization/{organization_id}/createuser/",
    CREATE_PARENT_USER: "/organization/{organization_id}/createparent/",
    //new enpoints Sergei
    COPY_ACTIVITY: "/activity/copy_to_user/",
    GET_PUBLIC_ACTIVITIES:"/activity/public/?include_scenes=false",
    ADD_TO_ORGANIZATION:"/user/organizations/",
    ACTIVITIES_TO_GRADE:"/activity/{activity_id}/grades/",
    DELETE_FROM_ORGANIZATION:"/user/organizations/",
    GET_ACTIVITIES_BY_OWNER: "/activity/owned/?include_scenes=false",
    USER_MAX_PARAMS:"/user/modify_max_params1/",
    ORGANIZATION_MAX_PARAMS:"/organization/modify_max_params2/",
    GET_OWN_LESSONS: "/grade/owned/",
    PUBLIC_AUTHORS:"/user/with_public_activities/",
    IMPORT_FROM_FILE: "/user/import_from_file/",
    TEACHER_LESSONS:"/grade/organization_teachers_only/", //?org_id
    ANYUSER_LESSONS:"/grade/owned/?",//?USER_id aulas amigas 6669 kidint 9693
    PUBLIC_LESSONS:"/grade/public_grade_activities/"//?lesson_id
  }
}

/*
^admin/$
admin/
^nested_admin/
api/ ^user/$ [name='user-list']
api/ ^user\.(?P<format>[a-z0-9]+)/?$ [name='user-list']
api/ ^user/profiles/$ [name='user-profiles']
api/ ^user/profiles\.(?P<format>[a-z0-9]+)/?$ [name='user-profiles']
api/ ^user/(?P<pk>[^/.]+)/$ [name='user-detail']
api/ ^user/(?P<pk>[^/.]+)\.(?P<format>[a-z0-9]+)/?$ [name='user-detail']
api/ ^organization/$ [name='organization-list']
api/ ^organization\.(?P<format>[a-z0-9]+)/?$ [name='organization-list']
api/ ^organization/(?P<pk>[^/.]+)/$ [name='organization-detail']
api/ ^organization/(?P<pk>[^/.]+)\.(?P<format>[a-z0-9]+)/?$ [name='organization-detail']
api/ ^grade/$ [name='grade-list']
api/ ^grade\.(?P<format>[a-z0-9]+)/?$ [name='grade-list']
api/ ^grade/organization/$ [name='grade-organization']
api/ ^grade/organization\.(?P<format>[a-z0-9]+)/?$ [name='grade-organization']
api/ ^grade/(?P<pk>[^/.]+)/$ [name='grade-detail']
api/ ^grade/(?P<pk>[^/.]+)\.(?P<format>[a-z0-9]+)/?$ [name='grade-detail']
api/ ^grade/(?P<pk>[^/.]+)/activities/$ [name='grade-activities']
api/ ^grade/(?P<pk>[^/.]+)/activities\.(?P<format>[a-z0-9]+)/?$ [name='grade-activities']
api/ ^grade/(?P<pk>[^/.]+)/install/$ [name='grade-install']
api/ ^grade/(?P<pk>[^/.]+)/install\.(?P<format>[a-z0-9]+)/?$ [name='grade-install']
api/ ^activity/$ [name='activity-list']
api/ ^activity\.(?P<format>[a-z0-9]+)/?$ [name='activity-list']
api/ ^activity/(?P<pk>[^/.]+)/$ [name='activity-detail']
api/ ^activity/(?P<pk>[^/.]+)\.(?P<format>[a-z0-9]+)/?$ [name='activity-detail']
api/ ^profilegroup/$ [name='profilegroup-list']
api/ ^profilegroup\.(?P<format>[a-z0-9]+)/?$ [name='profilegroup-list']
api/ ^profilegroup/(?P<pk>[^/.]+)/$ [name='profilegroup-detail']
api/ ^profilegroup/(?P<pk>[^/.]+)\.(?P<format>[a-z0-9]+)/?$ [name='profilegroup-detail']
api/ ^profile/$ [name='profile-list']
api/ ^profile\.(?P<format>[a-z0-9]+)/?$ [name='profile-list']
api/ ^profile/(?P<pk>[^/.]+)/$ [name='profile-detail']
api/ ^profile/(?P<pk>[^/.]+)\.(?P<format>[a-z0-9]+)/?$ [name='profile-detail']
api/ ^scene/$ [name='scene-list']
api/ ^scene\.(?P<format>[a-z0-9]+)/?$ [name='scene-list']
api/ ^scene/(?P<pk>[^/.]+)/$ [name='scene-detail']
api/ ^scene/(?P<pk>[^/.]+)\.(?P<format>[a-z0-9]+)/?$ [name='scene-detail']
api/ ^answer/$ [name='answer-list']
api/ ^answer\.(?P<format>[a-z0-9]+)/?$ [name='answer-list']
api/ ^answer/(?P<pk>[^/.]+)/$ [name='answer-detail']
api/ ^answer/(?P<pk>[^/.]+)\.(?P<format>[a-z0-9]+)/?$ [name='answer-detail']
api/ ^$ [name='api-root']
api/ ^\.(?P<format>[a-z0-9]+)/?$ [name='api-root']
api/ DataSimaProfesorSecuencia/ [name='get_activities']
api/ ProfesoresLista/ [name='get_grades']
api/ BuscarPerfilesID/ [name='get_profile']
api/ BuscarPerfiles/ [name='get_profiles']
api/ LoginInApp/ [name='app_login']
api/ CreatePerfil/ [name='create_profile']
api/ UpdatePerfil/ [name='update_profile']
api/ AgregarProfesor/ [name='add_grade_to_profile']
api/ DeleteProfesor/ [name='remove_grade_from_profile']
api/ RegistrarCuenta/ [name='register_user']
api/ rest-auth/
^$
^api/v2/ ^user/$ [name='user-list']
^api/v2/ ^user\.(?P<format>[a-z0-9]+)/?$ [name='user-list']
^api/v2/ ^user/profiles/$ [name='user-profiles']
^api/v2/ ^user/profiles\.(?P<format>[a-z0-9]+)/?$ [name='user-profiles']
^api/v2/ ^user/(?P<pk>[^/.]+)/$ [name='user-detail']
^api/v2/ ^user/(?P<pk>[^/.]+)\.(?P<format>[a-z0-9]+)/?$ [name='user-detail']
^api/v2/ ^organization/$ [name='organization-list']
^api/v2/ ^organization\.(?P<format>[a-z0-9]+)/?$ [name='organization-list']
^api/v2/ ^organization/(?P<pk>[^/.]+)/$ [name='organization-detail']
^api/v2/ ^organization/(?P<pk>[^/.]+)\.(?P<format>[a-z0-9]+)/?$ [name='organization-detail']
^api/v2/ ^grade/$ [name='grade-list']
^api/v2/ ^grade\.(?P<format>[a-z0-9]+)/?$ [name='grade-list']
^api/v2/ ^grade/organization/$ [name='grade-organization']
^api/v2/ ^grade/organization\.(?P<format>[a-z0-9]+)/?$ [name='grade-organization']
^api/v2/ ^grade/(?P<pk>[^/.]+)/$ [name='grade-detail']
^api/v2/ ^grade/(?P<pk>[^/.]+)\.(?P<format>[a-z0-9]+)/?$ [name='grade-detail']
^api/v2/ ^grade/(?P<pk>[^/.]+)/activities/$ [name='grade-activities']
^api/v2/ ^grade/(?P<pk>[^/.]+)/activities\.(?P<format>[a-z0-9]+)/?$ [name='grade-activities']
^api/v2/ ^grade/(?P<pk>[^/.]+)/install/$ [name='grade-install']
^api/v2/ ^grade/(?P<pk>[^/.]+)/install\.(?P<format>[a-z0-9]+)/?$ [name='grade-install']
^api/v2/ ^activity/$ [name='activity-list']
^api/v2/ ^activity\.(?P<format>[a-z0-9]+)/?$ [name='activity-list']
^api/v2/ ^activity/(?P<pk>[^/.]+)/$ [name='activity-detail']
^api/v2/ ^activity/(?P<pk>[^/.]+)\.(?P<format>[a-z0-9]+)/?$ [name='activity-detail']
^api/v2/ ^profilegroup/$ [name='profilegroup-list']
^api/v2/ ^profilegroup\.(?P<format>[a-z0-9]+)/?$ [name='profilegroup-list']
^api/v2/ ^profilegroup/(?P<pk>[^/.]+)/$ [name='profilegroup-detail']
^api/v2/ ^profilegroup/(?P<pk>[^/.]+)\.(?P<format>[a-z0-9]+)/?$ [name='profilegroup-detail']
^api/v2/ ^profile/$ [name='profile-list']
^api/v2/ ^profile\.(?P<format>[a-z0-9]+)/?$ [name='profile-list']
^api/v2/ ^profile/(?P<pk>[^/.]+)/$ [name='profile-detail']
^api/v2/ ^profile/(?P<pk>[^/.]+)\.(?P<format>[a-z0-9]+)/?$ [name='profile-detail']
^api/v2/ ^scene/$ [name='scene-list']
^api/v2/ ^scene\.(?P<format>[a-z0-9]+)/?$ [name='scene-list']
^api/v2/ ^scene/(?P<pk>[^/.]+)/$ [name='scene-detail']
^api/v2/ ^scene/(?P<pk>[^/.]+)\.(?P<format>[a-z0-9]+)/?$ [name='scene-detail']
^api/v2/ ^answer/$ [name='answer-list']
^api/v2/ ^answer\.(?P<format>[a-z0-9]+)/?$ [name='answer-list']
^api/v2/ ^answer/(?P<pk>[^/.]+)/$ [name='answer-detail']
^api/v2/ ^answer/(?P<pk>[^/.]+)\.(?P<format>[a-z0-9]+)/?$ [name='answer-detail']
^api/v2/ ^$ [name='api-root']
^api/v2/ ^\.(?P<format>[a-z0-9]+)/?$ [name='api-root']
^api/v2/ DataSimaProfesorSecuencia/ [name='get_activities']
^api/v2/ ProfesoresLista/ [name='get_grades']
^api/v2/ BuscarPerfilesID/ [name='get_profile']
^api/v2/ BuscarPerfiles/ [name='get_profiles']
^api/v2/ LoginInApp/ [name='app_login']
^api/v2/ CreatePerfil/ [name='create_profile']
^api/v2/ UpdatePerfil/ [name='update_profile']
^api/v2/ AgregarProfesor/ [name='add_grade_to_profile']
^api/v2/ DeleteProfesor/ [name='remove_grade_from_profile']
^api/v2/ RegistrarCuenta/ [name='register_user']
^api/v2/ rest-auth/
^accounts/login/$
accounts/
^health/
^api/media/(?P<path>.*)$
*/
